import React, { useEffect } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./drbach.scss"

const Home = ({ data }) => {
  gsap.registerPlugin(ScrollToPlugin)
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
  ]
  useEffect(() => {
    document.querySelectorAll("section li").forEach((li, index) => {
      li.addEventListener("click", e => {
        e.preventDefault()
        gsap.to(window, {
          scrollTo: { y: "#box" + (index + 1) },
          duration: 1.5,
          ease: "power4",
        })
      })
    })
  }, [])

  return (
    <>
      <SEO
        title="Dr. Bah"
        description="Dr. Edvard Bah (Edward Bach, 1886. – 1936.,
              Wales, UK) bio je ljekar, internista, patolog, bakteriolog,
              istraživač i homeopata. 1917. godine bio je operisan od karcinoma
              i kolege mu nisu davale više od 3 mjeseca života. Umjesto predaje,
              on se upotpunosti posvećuje svojoj ideji da medicini podari sistem
              liječenja koji će biti jednostavan, ali efikasan, gdje će se
              liječiti ljudi kao individue, a ne simptomi."
      />
      <Layout>
        <article id="drbach">
          <header>
            <div className="left-content">
              <h1>Dr. Bah</h1>
              <p>
                "Naše fizičko tijelo zavisi od našeg mišljenja, naših osjećaja i
                emocija"
              </p>
            </div>
            <Img fluid={sources} />
          </header>
          <section>
            <ul>
              <li>O Dr. Bah-u</li>
              <li>Bahova metoda</li>
            </ul>
            <p>
              Dobro zdravlje je harmonija, ritam, kada mislimo pozitivno,
              konstruktivno, sretno. Loše zdravlje je kada mislimo negativno,
              nesretno, destruktivno.
            </p>
            <h3 id="box1">Dr. Bah</h3>
            <p>
              <strong>Dr. Edvard Bah </strong>(Edward Bach, 1886. – 1936.,
              Wales, UK) bio je ljekar, internista, patolog, bakteriolog,
              istraživač i homeopata. 1917. godine bio je operisan od karcinoma
              i kolege mu nisu davale više od 3 mjeseca života. Umjesto predaje,
              on se upotpunosti posvećuje svojoj ideji da medicini podari sistem
              liječenja koji će biti jednostavan, ali efikasan, gdje će se
              liječiti ljudi kao individue, a ne simptomi. Smatrao je da je
              prirodno stanje ljudskog tijela da bude zdravo. U takvom
              raspoloženju, neosjetno su prošla ta 3 mjeseca, i još druga 3
              mjeseca... kada je on konstatovao da se osjeća mnogo bolje,
              zadovoljnije, a fizički simptomi bili su mnogo blaži i nisu mu
              više bili prepreka u svakodnevnici. Od momenta dijagnoze do
              njegove smrti, prošlo je mnogo puta po 3 mjeseca – prošlo je 19
              godina!
            </p>
            <p>
              Kada usljed poremećaja ravnoteže u ličnosti i emocijama odbrambene
              snage oslabe – pojavljuju se bolesti. Zato je Bah disbalans nazvao
              istinskim uzrokom bolesti. Kroz lično iskustvo je spoznao koliko
              su za zdravlje bitni: sreća, radost, raspoloženje, ushićenost i
              harmonija sa svojom prirodom. Dr. Bah je imao mnogo poštovanja
              prema ljudskom biću. Smatrao je da ljudi prirodno u sebi nose
              mnogo pozitivnih karakteristika kao što su ljubav, mudrost,
              saosjećajnost itd.
            </p>
            <p>
              Stil života kojim se živi i stepen civilizacije stvaraju prepreke
              u ljudima zbog kojih onda oni ne mogu da ispoljavaju pozitivnost.
              Te prepreke su strah, sebičnost, ravnodušnost, ogorčenost...
            </p>
            <p>
              Svjestan svega ovoga, dr. Bah je težio da napravi preparate koji
              će biti potpuno bezbjedni, a jednostavni za upotrebu i koji će
              pomoći ljudima da odnjeguju pozitivne osobine.
            </p>
            <p>
              Shvatio je da priroda nudi ono što čovjeku treba i krenuo je u
              potragu za biljkama. Dr. Bah je odgovor potražio u prirodi, jer
              polazeći od svijesti da nas priroda snadbijeva vodom, hranom,
              vazduhom, suncem, zaključio je da nas ona onda zasigurno
              snadbijeva i izvorima dobrobiti. Znao je da biljke, kao vrlo stara
              bića na planeti, imaju iskustva sa preživljavanjem, adaptacijom i
              življenjem u okruženju – znači one znaju kako se to radi i zato
              teba primiti te informacije od njih i koristiti ih i tako sebi i
              drugima uljepšati život. Priroda uvijek ima rješenje za naš
              opstanak i postojanje.
            </p>
            <p>
              Prije pronalaženja određenog cvijeta, u sebi je vrlo akutno patio,
              uz negativno stanje duha za koje je cvijet bio potreban, a u isto
              vrijeme je patio i od neke fizičke boljke. To ga je podsticalo da
              luta poljima i dolinama i da traži, a potom i nađe cvijet koji mu
              je vraćao spokojstvo i umni mir, a u roku od nekoliko sati i
              fizička boljka bi bila izlječena. Na taj način je pronašao 38
              cvijetova koji pokrivaju 38 negativnih stanja uma od kojih ljudski
              rod može da pati i jedan složeni preparat – Rescue Remedy, koji je
              kombinacija 5 od 38 pojedinačnih preparata. Svi preparati su
              biljnog porijekla, osim Rock Water koji je napravljen od izvorske
              vode, metodom osunčavanja. Za preparate su korišćeni samo cvjetovi
              koji rastu iznad zemlje, na suncu i vazduhu i sadrže u svom srcu
              sjeme embriona, nastavak života biljke.
            </p>
            <p>
              Njegovo traganje i upornost su se isplatili i stvoren je
              jedinstven sistem koji je primjenjiv za svaku vrstu čovjeka i
              svaku vrstu problema. Njegova primjena ne podrazumijeva visoko
              specijalizovano znanje korisnika i opremljenost skupim aparatima.
              <strong> "Self help"</strong>, samopomoć je bitna karakteristika
              medicinskog sistema dr. Baha. Smatrao je da je posao ljekara
              budućnosti da pomogne pacijentu da spozna sebe i svoje prepreke i
              da ožive zapuštene instinkte, koji bi im bili najbolji vodič za
              zdravlje.
            </p>
            <h3 id="box2">Bahova metoda</h3>
            <h4>Bahova metoda spada u holističku i komplementarnu medicinu</h4>
            <p>
              Pojam holistički znači<strong> "u cjelini, u cjelosti"</strong>.
              Za terapeute koji se bave fizičkim simptomima (npr. gripa,
              bronhitis, glavobolja, problemi varenja ,...) holistički pristup
              podrazumijeva da se intervju neće fokusirati na te simptome, jer
              su fizički simptomi samo posljedica našeg unutrašnjeg, emotivnog i
              mentalnog stanja, kvaliteta i načina na koji živimo i načina na
              koji se ophodimo prema sebi i prema drugima. Tu, dakle, leže
              uzroci naših fizičkih tegoba. Holistički pristup fokusira se na
              uzroke. To bi praktično značilo da će dvije različite osobe sa
              istim fizičkim tegobama dobiti različite kombinacije preparata,
              bez obzira što se žale na isti problem.
            </p>
            <p>
              Bahova metoda spada i u komplementarnu medicinu, što znači da se
              ona uklapa i sa drugim metodama, odnosno drugačijim načinima
              liječenja. Bahove kapi ne blokiraju, niti umanjuju dejstvo drugih
              preparata uzetih istovremeno, niti drugi preparati blokiraju
              dejstvo Bahovih kapi. Zato je zgodno, korisno, uzimati i Bahove
              kapi zajedno sa npr. farmakološkim ili homeopatskim preparatima,
              jer te kapi i mogu potpomagati ili ubrzati dejstvo i stepen
              efikasnosti drugih preparata. Bahove kapi je također korisno
              uzimati sa drugim preparatima koji imaju uzgredne negativne
              efekte. Pomažu pacijentu da lakše podnese negativne efekte
              primarnog liječenja i čisti od posljedica uzgrednih efekata
              farmakološkog ili homeopatskog preparata.
            </p>
            <p>
              Naravno, Bahove kapi koriste se i samostalno, kao jedina ili kao
              primarna metoda pomaganja.
            </p>
            <p>
              Dodatne informacije o Bahovim cvijetnim kapima možete naći na:
            </p>
            <div>
              <span></span>
              <a
                href="http://www.bachcentre.com"
                target="_blank"
                rel="noreferrer"
              >
                The Bach Centre
              </a>
            </div>
            <div>
              <span></span>
              <a
                href="http://www.bahovekapi.com"
                target="_blank"
                rel="noreferrer"
              >
                Bahove Cvetne Esencije
              </a>
            </div>
            <p></p>
          </section>
        </article>
      </Layout>
    </>
  )
}

export default Home

export const query = graphql`
  query {
    mobileImage: file(
      name: { eq: "bah-portret-sm" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopImage: file(
      name: { eq: "bach-portret-lg" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fluid(quality: 100, traceSVG: { color: "#005b4b80" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
